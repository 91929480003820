<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{
                            this.loyaltyTier.id
                                ? (this.modeView ? "" : $t("button.edit"))
                                : $t("button.add")
                        }} {{ $t("module.tierLoyalty") }}</h5>
                </div>
                <form @submit.prevent="submitData()">
                    <div class="modal-body">
                        <input
                            type="hidden"
                            :class="{'form-control':true , 'is-invalid':errors.has('name')}"
                            v-model.number="loyaltyTier.id"
                        />
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="inputEmail">{{ $t('common.name') }}* :</label>
                                <input
                                    type="text"
                                    v-validate="'required'"
                                    v-model="loyaltyTier.name"
                                    :disabled="modeView"
                                    name="name"
                                    :class="{'form-control':true , 'is-invalid':errors.has('name')}"
                                    id="name"
                                    :placeholder="$t('common.name')"
                                    :data-vv-as="$t('common.name')">
                                <div v-show="errors.first('name')" class="invalid-feedback" >{{ errors.first("name") }}</div>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="inputEmail">{{ $t('common.minimum_loyalty') }}* :</label>
                                <input type="number" step="1" pattern="[0-9]" v-validate="'required'" v-model="loyaltyTier.minimum" :disabled="modeView"
                                    max="99999999"
                                    name="minimum" :class="{'form-control':true , 'is-invalid':errors.has('minimum')}" id="minimum"
                                    :placeholder="$t('common.minimum_loyalty')"
                                    :data-vv-as="$t('common.minimum_loyalty')"
                                    >
                                <div v-show="errors.first('minimum')" class="invalid-feedback" >{{ errors.first("minimum") }}</div>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="inputEmail">{{ $t('common.color') }}* :</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input type="text" name="color" v-model="loyaltyTier.color"
                                            v-validate="'required'"
                                            :class="{'form-control':true , 'is-invalid':errors.has('color')}"
                                            :data-vv-as="$t('common.color')"
                                        />
                                        <div v-show="errors.first('color')" class="invalid-feedback" >{{ errors.first("color") }}</div>
                                    </div>
                                    <div class="col-md-9"><input type="color" v-model="loyaltyTier.color" class="current-color form-control"/></div>
                                </div>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="inputEmail">{{ $t('common.point_duration') }} :</label>
                                <input type="number" step="1" pattern="[0-9]" v-model="loyaltyTier.point_duration"
                                :disabled="modeView" :class="{'form-control':true , 'is-invalid':errors.has('point_duration')}"
                                name="point_duration" id="poin" :placeholder="$t('common.point_duration')"
                                :data-vv-as="$t('common.point_duration')">
                                <div v-show="errors.first('point_duration')" class="invalid-feedback" >{{ errors.first("point_duration") }}</div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="inputEmail">{{ $t('common.card') }}* :</label>
                                <div v-if="!modeView" name="card_content" id="summernote_card_content" class="summer-note"></div>
                                <div v-if="modeView"  v-html="loyaltyTier.card_content" name="card_content"
                                    id="summernote_card_content" class="summer-note"></div>

                                <div v-show="errors.first('card_content')"
                                    style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #fd397a">{{ errors.first("card_content") }}</div>

                            </div>
                            <div class="col-md-6 form-group">
                                <label for="inputEmail">{{ $t('common.card_thumbnail') }}* :</label>
                                <div v-if="!modeView" name="card_thumbnail"
                                    id="summernote_card_thumbnail"
                                    class="summer-note"></div>
                                <div v-if="modeView" v-html="loyaltyTier.card_thumbnail" name="card_thumbnail"
                                    id="summernote_card_thumbnail" class="summer-note"></div>

                                <div v-show="errors.first('card_thumbnail')" class="custom-invalid-feedback">{{ errors.first("card_thumbnail") }}</div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-12 form-group">
                                <label for="inputEmail">{{ $t('common.description') }}:</label>
                                <div v-if="!modeView" name="description" id="summernote_descriptions" class="summer-note"></div>
                                <div v-if="modeView" v-html="loyaltyTier.descriptions" name="description" id="summernote_descriptions"
                                    class="summer-note"></div>
                                <div v-show="errors.first('descriptions')" class="custom-invalid-feedback">{{ errors.first("descriptions") }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-clean btn-bold btn-upper btn-font-md"
                            @click="hideModal()"
                        >
                            {{ $t("button.close") }}
                        </button>
                        <button
                            v-if="!modeView"
                            type="submit"
                            class="btn btn-default btn-bold btn-upper btn-font-md"
                        >
                            {{ $t("button.save") }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import SweetAlertTemplate from "./../../../components/_general/SweetAlert";
import Photo from "./../../model/photo-model";
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
import { Validator } from "vee-validate";
import VeeValidate from "vee-validate";
import { isNullOrUndefined } from "util";
import LoyaltyTier from "./../../model/loyalty-tier-model";
import { mapState, mapMutations, mapActions } from "vuex";
import BlockUI from "./../../../components/_js/BlockUI";
const blockUI = new BlockUI();
const LoyaltyTierRepository = RepositoryFactory.get("loyaltyTierRepository");
const SweetAlert = Vue.extend(SweetAlertTemplate);

VeeValidate.setMode("passive");
export default {
    props: {
        event: {
            type: Function
        }
    },
    data() {
        return {
            defaultColor:'#ff00ff',
            loyaltyTier: new LoyaltyTier(),
            photo: new Photo(),
            isPickingColor:true
        };
    },
    provide() {
        return { $validator: this.$validator };
    },
    computed: {
        ...mapState("Crud", {
            modalName: state => state.modalName,
            dataEdit: state => state.dataEdit,
            showModalMode: state => state.showModalMode,
            isShowModal: state => state.isShowModal,
        }),
        modeView(){
            return this.showModalMode == 'V';
        }
    },

    methods: {
        toggleColorPicker(){
            this.isPickingColor = !this.isPickingColor;
        },
        summerNote(idEl,keyNameModel,contentCode){

            let config= {
                height:150,
                callbacks: {
                    onChange: (contents)=>{
                        this.loyaltyTier[keyNameModel]=contents;
                    },
                    onBlurCodeview:(contents, $editable)=>{
                        this.loyaltyTier[keyNameModel]=contents;
                    },
                }
            };
            $(idEl).summernote(config);

            if('string'==typeof(contentCode)){
                $(idEl).summernote('code',contentCode);
            }
            else if( null !== this.dataEdit && !_.isUndefined(this.dataEdit[keyNameModel])){
                $(idEl).summernote('code',this.dataEdit[keyNameModel]);
            }

        },
        destroySummerNote(){},
        ...mapActions({
            createNew: "Crud/createNew",
            updateById: "Crud/updateById"
        }),
        ...mapMutations({
            clearData: "Crud/CLEAR_DATA",
            showModal: "Crud/SHOW_MODAL",
            refreshData: "Crud/REFRESH_TABLE",
            setEditData: "Crud/SET_EDIT_DATA",
            hideModal: "Crud/HIDE_MODAL",
        }),
        getServerError(error, defaultMsg) {
            return _.isUndefined(error.response.data.message)? defaultMsg : error.response.data.message;
        },

        updateData: function() {
            if (this.dataEdit) {
                this.loyaltyTier = Object.assign({}, this.dataEdit);
            }
        },
        submitData: function() {
            let valid = true;
            this.errors.clear();
            if(this.loyaltyTier.card_content == null){
                this.errors.add({field: 'card_content', msg: 'Kartu Wajib diisi', rule: "required"});
                valid = false;
            }
            if(this.loyaltyTier.card_thumbnail == null){
                this.errors.add({field: 'card_thumbnail', msg: 'Thumbnail Kartu Wajib diisi', rule: "required"});
                valid = false;
            }
            if(this.loyaltyTier.descriptions == null){
                this.errors.add({field: 'descriptions', msg: 'Deskripsi Wajib diisi', rule: "required"});
                valid = false;
            }

            this.$validator.validateAll().then(result => {
                if (result) {
                    var vx = this;
                    if(!valid){
                        return;
                    }
                    if (this.loyaltyTier.id) {
                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Perubahan data akan tersimpan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                let params = {};
                                _.forEach(vx.loyaltyTier, (v, k) => {
                                    if(v != null){
                                        params[k] = v;
                                    }
                                });
                                vx.update(params, vx.loyaltyTier.id);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    } else {
                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Data akan ditambahkan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                const data = {};
                                _.forEach(vx.loyaltyTier, (v, k) => {
                                    if(v != null){
                                        data[k] = v;
                                    }

                                });
                                vx.create(data);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    }
                    return;
                }
            });
        }, //submitdata
        async create(payload) {
            var vx = this;
            await LoyaltyTierRepository.create(payload)
                .then(response => {
                    new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).
                        then((response) => {
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: 'Data Tier Loyalty berhasil ditambahkan', // response.data.message,
                            swalType: "success",
                            onConfirmButton: function () {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                                vx.hideModal();
                                }
                            });
                        }).catch(error=>{

                        });
                    })
                    .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                            error,
                            "Data Tier Loyalty gagal ditambahkan."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                    });
            },
            async update(payload,tierId) {
                var vx = this;

                await LoyaltyTierRepository.update(payload,tierId)
                    .then(response => {
                        new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).then((response) => {
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: 'Data Tier Loyalty berhasil diubah', //response.data.message,
                            swalType: "success",
                            onConfirmButton: function() {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                            }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                            error,
                            "Data Tier Loyalty gagal diubah."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
        }
    }, //methods
    created(){

    },
    mounted() {
        if(!this.modeView){
            this.summerNote('#summernote_card_content','card_content');
            this.summerNote('#summernote_card_thumbnail','card_thumbnail');
            this.summerNote('#summernote_descriptions','descriptions');
        }
        window.thisFormModal = this;
        $("#" + this.modalName).modal();
        this.updateData();
    }
};
</script>
