export default function LoyaltyTier() {
    return {
        id: null,
        name: null,
        point_duration: null,
        minimum: null,
        color: null,
        card_content: null,
        card_thumbnail: null,
        descriptions: null
    };
}
